exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-blog-js": () => import("./../../../src/pages/blog/blog.js" /* webpackChunkName: "component---src-pages-blog-blog-js" */),
  "component---src-pages-blog-cbct-js": () => import("./../../../src/pages/blog/cbct.js" /* webpackChunkName: "component---src-pages-blog-cbct-js" */),
  "component---src-pages-blog-combi-touch-js": () => import("./../../../src/pages/blog/combi-touch.js" /* webpackChunkName: "component---src-pages-blog-combi-touch-js" */),
  "component---src-pages-blog-diga-di-gomma-js": () => import("./../../../src/pages/blog/diga-di-gomma.js" /* webpackChunkName: "component---src-pages-blog-diga-di-gomma-js" */),
  "component---src-pages-blog-scanner-intraorale-js": () => import("./../../../src/pages/blog/scanner-intraorale.js" /* webpackChunkName: "component---src-pages-blog-scanner-intraorale-js" */),
  "component---src-pages-blog-sterilizzazione-js": () => import("./../../../src/pages/blog/sterilizzazione.js" /* webpackChunkName: "component---src-pages-blog-sterilizzazione-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../../../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-trattamenti-js": () => import("./../../../src/pages/trattamenti.js" /* webpackChunkName: "component---src-pages-trattamenti-js" */)
}

